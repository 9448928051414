<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container blog_text blog-post-wrapper">
            <div class="col-md-10 col-lg-8 mx-auto">
                <div class="mb-4">
                    <h1>
                        The Complete Tax Guide for F-1 / J-1 International
                        Students in the U.S.
                    </h1>
                    <p class="blog_author_text my-4">
                        The Thriftax Team
                        <br />
                        Mar 25 2021 · 14 min read
                    </p>
                </div>

                <div class="position-relative mb-5">
                    <img
                        class="card-img"
                        :src="getImgUrl('students_2')"
                        alt="Image Description"
                    />
                </div>
                <p>
                    You probably heard that taxes are taken extremely seriously
                    in the U.S. – Uncle Sam is requiring and expecting anyone
                    who needs to file and report their income to comply with tax
                    laws. Of course, this requirement also applies to
                    international students, scholars, researchers, and
                    professors studying, teaching or conducting research in the
                    United States. Filing taxes in a foreign country might be
                    challenging, so to make your life a bit easier we are happy
                    to bring you this ultimate tax guide for international
                    students on F-1 or J-1 visas and other nonresident aliens on
                    other visas. The best part – we truly made sure this guide
                    is robust, yet easy to navigate and understand. So, let’s
                    begin:
                </p>

                <ul class="pl-4">
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._0,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            U.S. tax filing compliance and implications on
                            international students and scholars
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._1,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Different types of U.S. taxes – Federal, FICA
                            (social security and Medicare) and state
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._2,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Tax Refund vs. Tax Due
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._3,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Determining your residency status for tax purposes –
                            the substantial presence test
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._4,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            F-1 and J-1 Exempt individuals
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._5,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            When does an F-1 / J-1 visa holder become a resident
                            alien?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._6,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Residency status pros and cons
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._7,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Who needs to file
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._8,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What tax forms should I file
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._9,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What is form 1040-NR – Nonresident Tax Return
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._10,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What is form 8843
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._11,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            How to file a tax return as an international student
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._12,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Filing deadlines – 8843, 1040-NR and state returns
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._13,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What is considered as income
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._14,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Introduction to U.S. income reporting documents –
                            W-2, 1099 and more
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._15,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            International Student with U.S. Income
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._16,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What if I invested in stocks / options / ETFs /
                            Crypto Currency – Capital Gains Tax
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._17,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What if I had self-employment income?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._18,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Tax treaties for international students
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._19,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Tax deductions for international students
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._20,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Tax Credits for international students
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._21,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Can I deduct my student loan interest payments?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._22,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Can I deduct the interest payments of a non-US
                            student loan?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._23,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Can an international student use TurboTax, TaxSlayer
                            or H&R Block?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._24,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            How to claim a FICA tax return
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._25,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Visa change from F-1 / J-1 to H1-B
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._26,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            Can I file with Thriftax?
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:;"
                            @click="
                                $smoothScroll({
                                    scrollTo: $refs._27,
                                    duration: 1000,
                                    offset: -85,
                                })
                            "
                        >
                            What are the benefits of filing with Thriftax?
                        </a>
                    </li>
                </ul>

                <h3 class="mt-5" ref="_0">
                    U.S. tax filing compliance and implications on international
                    students and scholars
                </h3>

                <p>
                    As a foreign student or scholar in the U.S. you are expected
                    to comply with the U.S. tax laws. It is important to:
                </p>

                <ol class="pl-4" type="a">
                    <li>
                        File according to your residency status – Nonresident
                        Alien or Resident Alien
                    </li>
                    <li>File on time – file before the deadline</li>
                    <li>
                        File transparently – report everything you should be
                        reporting, do not omit income
                    </li>
                    <li>
                        File accurately – take only allowed deductions,
                        calculate correctly
                    </li>
                    <li>
                        Include the required schedules (additional supplemental
                        forms) and relevant statements
                    </li>
                </ol>

                <p class="mt-5">
                    You are obligated to comply with the U.S. tax laws according
                    to your visa terms. <br />
                    <br />
                    Filing compliance is important and if not met may result in
                    severe implications for your visa good standing status,
                    future U.S. permanent residency or citizenship applications,
                    fines and other sanctions and in extreme cases even a
                    criminal record.
                </p>
                <h3 class="mt-5" ref="_1">
                    Different types of U.S. taxes – Federal, FICA (social
                    security and Medicare) and state
                </h3>
                <p>
                    <u>Federal Tax</u> – This is an income tax that’s imposed by
                    the federal government on income originated in any of the 50
                    states of the U.S. and outside of the U.S. (for residents
                    for tax purposes). <br /><br />
                    <u> FICA (social security and Medicare) taxes </u>
                    – These taxes are imposed on the social security
                    administration and can be seen and used to fund retirement
                    payments, Medicare, and some disability payments. We can
                    consider FICA taxes as social insurance in a way.
                    <br /><br />
                    <u> State Tax </u> – Income tax that is imposed by states
                    that aren’t income tax-free. The 9 income tax-free states
                    are: Alaska, Florida, Nevada, New Hampshire, South Dakota,
                    Tennessee, Texas, Washington and Wyoming. States usually tax
                    income that’s originated within their territory or outside
                    of their territory if the taxpayer is the state’s resident.
                </p>
                <h3 class="mt-5" ref="_2">Tax Refund vs. Tax Due</h3>
                <p>
                    When you make income, in many cases taxes are being withheld
                    on your behalf by the payer. The most common case is taxes
                    that are withheld by your employer. These taxes are being
                    paid directly by the payer / employer to the tax authorities
                    (IRS, state, etc.). At the end of the calendar year each
                    taxpayer will file a tax return and report all their income
                    for that year. Based on the reported income and taxes
                    withheld, one of the 3 scenarios will be relevant to you:
                </p>
                <ol class="pl-4" type="a">
                    <li>
                        Tax refund – If your withheld taxes were higher than
                        what you were required to pay based on your reported
                        income.
                    </li>
                    <li>
                        0 Balance – If your withheld taxes equal the taxes you
                        should be paying
                    </li>
                    <li>
                        Tax due – if your withheld taxes are lower than the
                        taxes you should be paying.
                    </li>
                </ol>
                <p>
                    The term “tax return” can be misleading due to the word
                    “return”, as regardless if you are eligible for a refund,
                    have a 0 balance or have a tax due required to be paid, you
                    MUST file a tax return.
                </p>
                <h3 class="mt-5" ref="_3">
                    Determining the residency status for tax purposes – the
                    substantial presence test
                </h3>
                <p>
                    Unless you held a green card at any point of the tax year,
                    in order to be considered a resident for tax purposes you
                    should pass the substantial presence test (and not be an
                    exempt individual – see below). According to this test your
                    residency status is determined based on the number of days
                    you were present in the U.S. during the tax year and the two
                    prior years. Let’s determine your residency status:
                </p>

                <ol type="a" class="pl-4 mt-2">
                    <li class="mb-2">
                        Were you physically present in the U.S. for at least 31
                        days during the tax year?
                        <br />
                        No – You are a non-resident alien
                        <br />
                        Yes – Continue to (b)
                    </li>
                    <li>
                        Count the number of days you were in the U.S. as
                        follows:
                        <ul class="pl-4">
                            <li>
                                <strong>
                                    All the days in the current tax
                                    year.</strong
                                >
                                Count all the days you were present in the U.S.
                                during the tax year. For instance, if you are
                                filing a
                                {{ new Date().getFullYear() - 1 }}
                                tax return, count all the days you were present
                                in the U.S. in
                                {{ new Date().getFullYear() - 1 }}.
                            </li>
                            <li>
                                <strong
                                    >1/3 of the days 1 year prior the tax
                                    year.</strong
                                >
                                Count the days you were present in the U.S. in
                                the year prior to the tax year and divide the
                                result by 3. If you are filing a
                                {{ new Date().getFullYear() - 1 }}
                                tax return and were present in the U.S. for 120
                                days in 2019 you should count 120/3=40 days.
                            </li>
                            <li>
                                <strong>
                                    1/6 of the days 2 years prior the tax year.
                                </strong>
                                Count the days you were present in the U.S. in
                                the year prior to the tax year and divide the
                                result by 6. If you are filing a
                                {{ new Date().getFullYear() - 1 }}
                                tax return and were present in the U.S. for 180
                                days in 2018 you should count 180/6=20 days.
                            </li>
                        </ul>
                    </li>
                </ol>
                <p>
                    Now sum the results from each year based on the calculation
                    above. Did you get a number higher than 183 days? You are a
                    resident alien (unless you are on an F, J, Q or M visa). Got
                    a number lower than 183 days? You are a nonresident alien.
                </p>
                <h3 class="mt-5" ref="_4">F-1 and J-1 Exempt individuals</h3>
                <p>
                    F-1 and J-1 visa holders (as well as their F-2 and J-2
                    dependents) are exempt from the substantial presence test,
                    which means that one cannot count days you were present in
                    the U.S. on these visas until the exemption period ends. For
                    students, that period is 5 years. For scholars, researchers
                    and professors, it’s 2 years. If you are not sure about your
                    residency status, Thriftax can help you determine it. When
                    you start filing with Thriftax we always review your
                    residency status first.
                </p>
                <h3 class="mt-5" ref="_5">
                    When does an F-1 / J-1 visa holder become a resident alien?
                </h3>
                <p>
                    Students in the 6th calendar year after arriving to the US
                    become resident aliens. For instance, if you arrived in the
                    U.S. on a student visa (either F-1, J-1 or M-1) and entered
                    first on December 11th,
                    {{ new Date().getFullYear() - 3 }}. You will exclude all the
                    days you were present in the U.S. on your student visa for 5
                    calendar years – {{ new Date().getFullYear() - 3 }},
                    {{ new Date().getFullYear() - 2 }},
                    {{ new Date().getFullYear() - 1 }},
                    {{ new Date().getFullYear() }}, and
                    {{ new Date().getFullYear() + 1 }}. In
                    {{ new Date().getFullYear() + 2 }} you can start counting
                    days towards the substantial presence test and since you
                    can’t count the days from the 2 previous years you’ll have
                    to be in the U.S. at least 183 days in
                    {{ new Date().getFullYear() + 2 }} to be a resident alien.
                    <br />
                    <br />
                    For Scholars, researchers and professors on J-1 visas,
                    counting days is possible in the 3rd calendar year after the
                    date of arrival.
                </p>
                <h2 class="mt-6 mb-3" ref="_6">
                    Residency status pros and cons
                </h2>
                <p>
                    Although you cannot select your residency status, you might
                    be wondering about the pros and cons of each status, so
                    let’s quickly review them:
                </p>
                <h3 class="font-weight-bold">Non-resident Alien Pros:</h3>
                <ul class="pl-4">
                    <li>
                        The biggest benefit of filing as a non-resident on a F-1
                        or J-1 visa is the exemption from the 7.65% FICA taxes
                        (so long you are a non-resident alien!). FICA taxes are
                        Social Security and Medicare taxes. Note that some
                        employers may withhold these taxes from you, in that
                        case, Thriftax can help you recoup these taxes by filing
                        a special FICA tax return.
                    </li>
                    <li>
                        Can utilize tax treaties to reduce their taxable income.
                        Tax treaties are available for employment income,
                        fellowship income and even capital gains. Your country
                        of residence will determine the specific benefits.
                    </li>
                    <li>Exempt from paying taxes on non-U.S. income.</li>
                </ul>
                <h3 class="font-weight-bold">Non-resident Alien Cons:</h3>
                <ul class="pl-4">
                    <li>
                        Cannot file joint tax returns – this is relevant for
                        married non-residents.
                    </li>
                    <li>
                        Cannot take the standard deduction. Note: Indian
                        residents can take the standard deduction under a tax
                        treaty.
                    </li>
                    <li>
                        Cannot utilize child and other dependent tax credits.
                        Note: residents of Canada, Mexico, India and South Korea
                        may be able take these credits under a tax treaty,
                        depending on their children’s residency and other
                        factors.
                    </li>
                </ul>
                <h3 class="font-weight-bold">Resident Alien Pros:</h3>
                <ul class="pl-4">
                    <li>Can take the standard deduction</li>
                    <li>
                        Can file joint tax returns (relevant for married
                        couples)
                    </li>
                    <li>Can utilize child and other dependent tax credits</li>
                </ul>
                <h3 class="font-weight-bold">Resident Alien Cons:</h3>
                <ul class="pl-4">
                    <li>
                        MUST pay FICA taxes, even if they are on a visa that
                        would have provided them with this exemption as a
                        non-resident alien.
                    </li>
                    <li>
                        Must report and pay taxes on income from outside the
                        U.S.
                    </li>
                </ul>
                <h3 class="mt-5" ref="_7">Who needs to file</h3>
                <p>
                    A full tax return should be filed by any international
                    student (or other non-resident) who had a U.S. income.
                    During the tax year (the year you are filing for). If your
                    income is from a state that imposes state income tax or if
                    you are a resident of such state you should also file a
                    state tax return.
                    <br /><br />
                    If you are on a F, J, M or Q visa you need to file form 8843
                    only.
                </p>
                <h3 class="mt-5" ref="_8">What tax forms should I file?</h3>
                <p>
                    If you had a U.S. income – you should file form 1040-NR. In
                    addition, you should file form 8843 if you are on a F, J, M
                    or Q visa.
                    <br /><br />
                    Based on your case you might also need to file a state
                    return (forms vary from state to state). <br /><br />
                    If you had no U.S. income and have an F-1 or J-1 visa you
                    should file form 8843.
                </p>

                <h3 class="mt-5" ref="_9">
                    What is form 1040-NR – Nonresident Tax Return?
                </h3>
                <p>
                    Form 1040-NR is the form used to report nonresident taxes
                    and calculate the federal taxes. Form 1040-NR comes with a
                    variety of schedules (additional supplemental forms) that
                    may be required depending on your case.
                </p>

                <h3 class="mt-5" ref="_10">What is form 8843?</h3>
                <p>
                    Form 8843 is a form required by any nonresident on an F, J,
                    M or Q visa. It’s used to report your status and presence in
                    the U.S on these visas to the IRS and distinguish you as an
                    exempt individual as explained above.
                </p>

                <h3 class="mt-5" ref="_11">
                    How to file a tax return as an international student
                </h3>
                <p>
                    To file a tax return as an international student you need to
                    file form 1040-NR, be sure to include all the required
                    schedules. Additionally, you should also attach form 8843 to
                    your tax return if you are a student on an F-1 or J-1 visa.
                </p>

                <h3 class="mt-5" ref="_12">
                    Filing deadlines – 8843, 1040-NR and state returns
                </h3>
                <p>
                    The deadline for your federal tax return (form 1040NR) is
                    April 15th. Note: this year the IRS extended the deadline to
                    May 17th 2021. State tax return deadlines are aligned with
                    the IRS deadlines. If you are only required to file 8843
                    (you had no income) then the deadline is June 15th.
                </p>

                <h3 class="mt-5" ref="_13">What’s considered as income?</h3>
                <p>
                    Some examples include both on and off-campus employment
                    income (including OPT or academic training income),
                    self-employment, prizes, scholarships fellowships and other
                    grants, capital gains from investments and trades, gambling
                    winnings and more.
                </p>

                <h3 class="mt-5" ref="_14">
                    Introduction to U.S. income reporting documents – W-2, 1099
                    and more
                </h3>
                <p>
                    W-2 – used to report employment income and is provided by
                    your employer. <br />
                    <br />
                    1042-S – used to report a variety of income types paid to
                    nonresidents, including employment, prizes, scholarships,
                    interest, dividends and more. <br />
                    <br />
                    1099-INT – used to report interest income from deposits,
                    bonds, the IRS, or other governmental authorities. <br />
                    <br />
                    1099-B – used to report capital gain(s) from investment(s)
                    with a broker (e.g. stocks, options, ETFs, crypto currencies
                    etc.) <br />
                    <br />
                    1099-DIV – used to report dividends and capital distribution
                    income.
                    <br />
                    <br />
                    1099-G – used to report payments from governmental
                    institutions such as a previous year’s tax refund,
                    unemployment income etc. <br />
                    <br />
                    1099-MISC – used to report miscellaneous income such as
                    scholarships, fellowships, prizes and awards, and more.
                    <br />
                    <br />
                    1099-NEC – used to report income from contracting or
                    business operations.
                    <br />
                    <br />
                    1099-R – used to report pension or annuity payments,
                    including early withdrawals from pension plans such as a
                    401K or 401K-ruth etc. <br />
                    <br />
                </p>

                <h3 class="mt-5" ref="_15">
                    What to do if I had a U.S. Income
                </h3>
                <p>
                    If you had a U.S. income during the tax year, you should
                    file a tax return. Depending on your state residency status
                    and/or the state your income is originated from, you may
                    also be required to file a state tax return. Please note,
                    you always file a tax return for the previous calendar year
                    that’s already ended, thus you should always only consider
                    income made during that year and not your current year’s
                    income.
                </p>

                <h3 class="mt-5" ref="_16">
                    What if I invested in stocks / options / ETFs / Crypto
                    Currency – Capital Gains Tax
                </h3>
                <p>
                    If you invested through a U.S. broker such as Robinhood,
                    WeBull, E*trade, Merrill Edge etc. you should receive forms
                    reporting your investment activities such as 1099-B,
                    1099-DIV, 1099-INT etc. If you have invested through a
                    foreign non-U.S. broker, then as a nonresident you are not
                    required to report this income and won’t be taxed on it in
                    the U.S.
                </p>

                <h3 class="mt-5" ref="_17">
                    What if I had self-employment income?
                </h3>
                <p>
                    Self-employment income from any work you made while in the
                    U.S requires you to file a tax return. If you were
                    self-employed outside of the U.S., you are not required to
                    report this income and won’t be taxed on it.
                </p>

                <h3 class="mt-5" ref="_18">
                    Tax treaties for international students
                </h3>
                <p>
                    Many countries have signed on tax treaties with the United
                    States. A tax treaty is an agreement between 2 countries
                    about how residents of these countries will be taxed on
                    income made in the other country. There are many tax
                    treaties that can exempt income you made in the U.S. from
                    taxation. Thriftax utilizes all the available tax treaties
                    for international students and other nonresident aliens for
                    a variety of income types such as employment income,
                    self-employment income, scholarships income, capital gain
                    income, dividend income, interest income and more. Your
                    actual tax treaty benefits will be determined based on your
                    country of residence which is usually also your country of
                    citizenship.
                </p>

                <h3 class="mt-5" ref="_19">
                    Tax deductions for international students
                </h3>
                <p>
                    Tax deductions are expenses recognized as eligible to be
                    deducted from your taxable income, such as interest paid on
                    a student loans, self-employment expenses, state taxes,
                    school-related expenses and even charity donations.
                    Eligibility for deductions is determined by your status,
                    source of income, and other factors. Thriftax will recognize
                    which deductions you qualify for and tailor the filing
                    experience based on your case so you will always comply with
                    the IRS’s requirements and guidelines.
                </p>

                <h3 class="mt-5" ref="_20">
                    Tax Credits for international students
                </h3>
                <p>
                    A tax credit is a $ amount you may be eligible to utilize to
                    reduce the taxes you pay and, in some cases, receive a
                    refund without even paying taxes in the first place. There
                    are two types of tax credits refundable and non-refundable.
                    A refundable tax credit can grant you a tax refund of a
                    certain amount even if you have not had any income that you
                    paid taxes for during the tax year. A non-refundable tax
                    credit can only be used if you had taxable income during the
                    tax year and may be used to offset these taxes all the way
                    down to zero. Your eligibility for tax credits is determined
                    based on your country of residency, dependents residency,
                    and other factors.
                </p>

                <h3 class="mt-5" ref="_21">
                    Can I deduct my student loan interest payments?
                </h3>
                <p>
                    You may be able to deduct student loan interest that you
                    have paid during the tax year. It is important to
                    distinguish between paid interest and accrued interest.
                    Interest that was accrued to your loan’s balance but was not
                    paid to the lender is not eligible for a deduction. What’s
                    more? If your lender is a U.S. lender you should receive
                    form 1098-E which indicates your deductible payments. Please
                    note you cannot deduct principal repayments. Please note
                    that you cannot deduct student loan interest payments if you
                    were married during the tax year. Thriftax can help you
                    ensure you are filing in compliance with both federal and
                    state tax laws and guidelines.
                </p>

                <h3 class="mt-5" ref="_22">
                    Can I deduct the interest payments of a non-US student loan?
                </h3>
                <p>
                    Yes! As an international student you may have taken your
                    student loan from your home country, but that does not
                    disqualify you from deducting your student loan’s interest
                    payments. Be sure to ask from your lender to provide a form
                    that indicates the interest portion of your loan repayments.
                    Thriftax can help you ensure you are filing in compliance
                    with both federal and state tax laws and guidelines.
                </p>

                <h3 class="mt-5" ref="_23">
                    Can an international student use TurboTax, TaxSlayer or H&R
                    Block?
                </h3>
                <p>
                    Whether you can or can’t use resident tax return software
                    such as TurboTax, TaxSlayer, H&R Block etc. depends on your
                    residency status as explained above (see residency). If you
                    are in the U.S. on a F-1 or J-1 visa, you are most likely a
                    nonresident alien during the calendar years that you were an
                    exempt individual in (see F-1 and J-1 exempt individuals).
                    Is that bad news? Not at all! First, as a nonresident you
                    may be eligible for tax treaty benefits. Second,
                    international students on F-1 and J-1 visas are exempt from
                    paying social security and Medicare taxes in the rate of
                    7.65% of their income. Most large employers are aware of
                    these exemptions and will not withhold taxes from you. If
                    you file with a resident software and weren't paying social
                    security and Medicare taxes, that might be considered as
                    fraud and not just a mistake. Be sure to file with the right
                    software and file according to your residency status.
                </p>

                <h3 class="mt-5" ref="_24">How to claim a FICA tax return</h3>
                <p>
                    As a nonresident alien and international student on an F-1
                    or J-1 visa, you are exempt from paying FICA (social
                    security and Medicare) taxes. However, some employers will
                    withhold FICA taxes from you in error. Luckily, you can file
                    a FICA tax return. In order to file a FICA tax return, you
                    need to file form 843 and from 8316. Thriftax always checks
                    for eligibility for FICA returns and if eligible, allows you
                    to file a FICA return as well. Please note that a FICA
                    return is a completely different process than the income tax
                    return, and may take longer to receive a refund.
                </p>

                <h3 class="mt-5" ref="_25">Visa change from F-1/J-1 to H1-B</h3>
                <p>
                    If you change from a student / scholar visa to H1-B, you
                    most likely did so in October of the tax year. Since your
                    days in the U.S. on the F-1 / J-1 visa are exempt from the
                    substantial presence test (see determining the residency
                    status), you will most likely remain a nonresident for the
                    year you made the visa change. If you changed your visa,
                    Thriftax can recognize your residency status and prepare
                    H1-B visa holders tax returns.
                </p>

                <h3 class="mt-5" ref="_26">Can I file with Thriftax?</h3>
                <p>
                    If you are a nonresident alien, you are welcome to file with
                    Thriftax. Since most international students on F-1 and J-1
                    visas are nonresidents for tax purposes, you can file with
                    Thriftax. If you are not sure what your residency status is,
                    do not worry. Thriftax’s software will recognize your
                    residency status in the first step of the filing process,
                    and you will only be able to move to next steps if you are
                    deemed a nonresident.
                </p>

                <h3 class="mt-5" ref="_27">
                    What are the benefits of filing with Thriftax?
                </h3>
                <p>Filing with Thriftax provides you:</p>
                <ul class="pl-4">
                    <li>
                        A tax return form that truly maximizes your tax refund
                        by utilizing over 150 tax treaties, deductions, and
                        credits.
                    </li>
                    <li>100% accuracy and compliance with tax laws.</li>
                    <li>
                        Time saving – file in as low as 13 minutes. The software
                        will constantly tailor the questions you see based on
                        your previous answers and your case, which helps save
                        time and prevent confusion.
                    </li>
                    <li>
                        Get FICA tax refund if it was withheld from you by
                        error.
                    </li>
                    <li>
                        Detailed, yet simple instructions for how to handle your
                        tax returns, how to mail them, and to which address.
                    </li>
                    <li>Simple and intuitive tax filing experience.</li>
                    <li>One-click away customer support and much more.</li>
                </ul>
                <p>
                    File with Thriftax today and get the tax refund you deserve.
                </p>

                <ReadyToFileBanner class="mt-8" />
                <blog-badges />
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
import ReadyToFileBanner from '@/components/landings/ready-to-file-banner';
import BlogBadges from './blog-badges';

export default {
    components: {
        ReadyToFileBanner: ReadyToFileBanner,
        BlogBadges,
    },
    methods: {
        getImgUrl(image) {
            var images = require.context('@/assets/img/', false, /\.jpg$/);
            return images('./' + image + '.jpg');
        },
    },

    metaInfo: {
        title:
            'The F-1 / J-1 International Student tax guide – all you need to know to survive the tax filing season',
        meta: [
            {
                name: 'description',
                content:
                    'International students on F-1 or J-1 visas are required to file form 8843 and, in the case they had a U.S.-based income, a complete tax return as well. Some of the benefits include FICA tax exemption (or refund), tax treaty benefits, and more.',
            },
        ],
    },
};
</script>

<style scoped>
ul li {
    /* Bullet color */
    list-style-type: disc;
}
</style>
